<template>
   <main>
      <section class="checkout-area py-md-5 py-3">
         <div class="container">
            <div class="row">
               <div class="col-lg-6">
                  <div class="checkbox-form">
                     <h3 class="mb-0">Carrinho</h3>
                     <produto v-for="(produto, index) in carrinho.itens" :key="index" :produto="produto" :index="index" :page="'FINALIZAR'" />
                  </div>
               </div>
               <div class="col-lg-6 mt-4 mt-lg-0">
                  <div class="checkbox-form" v-if="dados != null">
                     <h3 class="mb-0">Dados cadastrados</h3>
                     <div class="col-12">
                        <div class="your-order-table table-responsive">
                           <table>
                              <tbody>
                                 <tr class="cart_item">
                                    <td class="w-50 product-name regulaTexto">
                                       <strong class="product-quantity"><i class="fal fa-id-card color-theme font-12 me-1"></i> CPF</strong>
                                    </td>
                                    <td class="w-50 product-total regulaTexto py-2">
                                       <input type="text" class="form-control font-14" maxlength="250" v-model="dados.user.cpf" v-mask="['###.###.###-##', '##.###.###/####-##']" />
                                    </td>
                                 </tr>
                                 <tr class="cart_item">
                                    <td class="w-50 product-name regulaTexto">
                                       <strong class="product-quantity"><i class="far fa-hashtag color-theme font-12 me-1"></i> ID Jogador</strong>
                                    </td>
                                    <td class="w-50 product-total regulaTexto py-2">
                                       <input type="text" class="form-control font-14" v-mask="'##########'" v-model="dados.user.idJogador" />
                                    </td>
                                 </tr>
                              </tbody>
                           </table>
                        </div>
                     </div>

                     <h3 class="pt-5 mb-0">Pagamento</h3>
                     <div class="col-12">
                        <div class="checkbox-form">
                           <div class="col-12">
                              <div class="your-order-table table-responsive">
                                 <table>
                                    <tbody>
                                       <tr class="cart_item">
                                          <td class="w-50 product-name regulaTexto">
                                             <strong class="product-quantity"><i class="far fa-money-bill-wave-alt color-theme font-12 me-1"></i> Valor da compra</strong>
                                          </td>
                                          <td class="w-50 product-total regulaTexto text-end">
                                             <span class="amount">
                                                <strong>R$ {{ parseFloat(carrinho.valorTotal - dadosCompra.descontoTotal).toFixed(2) }}</strong>
                                             </span>
                                          </td>
                                       </tr>
                                       <tr class="cart_item">
                                          <td class="w-100 product-total regulaTexto py-2">
                                             <textarea class="form-control font-14" maxlength="250" v-model="dadosCompra.observacoes" placeholder="Observações sobre o pedido ou forma de pagamento..."></textarea>
                                          </td>
                                       </tr>
                                       <tr class="cart_item">
                                          <div class="row m-0">
                                             <td class="w-50 product-name regulaTexto">
                                                <strong class="product-quantity"><i class="far fa-tag color-theme font-12 me-1"></i> Cupom de desconto</strong>
                                             </td>
                                             <td class="w-50 product-total regulaTexto py-2">
                                                <input type="text" class="form-control font-14" ref="cupom" v-model="dadosCompra.cupom" @keyup.enter="aplicarCupom" placeholder="Pressione enter p/ aplicar" />
                                             </td>
                                          </div>
                                       </tr>
                                       <tr class="cart_item">
                                          <td class="w-50 product-name regulaTexto">
                                             <strong class="product-quantity"><i class="far fa-wallet color-theme font-12 me-1"></i> Forma de pagamento</strong>
                                          </td>
                                          <td class="w-50 product-total regulaTexto py-2">
                                             <select class="form-select font-14" v-model="dadosCompra.formaPgto">
                                                <option v-if="config.apiKeyPagHiper != null && config.apiKeyPagHiper.trim().length > 0 && 
                                                   config.tokenPagHiper != null && config.tokenPagHiper.trim().length > 0">PagHiper</option>
                                                <option v-if="config.tokenMercadoPago != null && config.tokenMercadoPago.trim().length > 0">MercadoPago</option>
                                                <option v-if="config.clientIdPayPal != null && config.clientIdPayPal.trim().length > 0 &&
                                                   config.clientSecretPaypal != null && config.clientSecretPaypal.trim().length > 0">PayPal</option>
                                                <option v-if="(config.apiKeyPagHiper == null || config.apiKeyPagHiper.trim().length == 0) 
                                                   && (config.tokenPagHiper == null || config.tokenPagHiper.trim().length == 0) 
                                                   && (config.clientIdPayPal == null || config.clientIdPayPal.trim().length == 0) 
                                                   && (config.clientSecretPaypal == null || config.clientSecretPaypal.trim().length == 0) 
                                                   && (config.apiKeyPagHiper == null || config.apiKeyPagHiper.trim().length == 0)">Pagamento não configurado</option>
                                             </select>
                                          </td>
                                       </tr>
                                       <tr class="cart_item" v-if="dadosCompra.formaPgto == 'PagHiper'">
                                          <td class="w-100 product-total text-end">
                                             <div class="btn-pix mx-auto" @click="pagHiper">
                                                <img src="@/assets/cliente/img/shop/pix.png" alt="Pix" />
                                             </div>
                                          </td>
                                       </tr>
                                       <tr class="cart_item" v-else-if="dadosCompra.formaPgto == 'PayPal'">
                                          <td class="w-100 product-total text-end">
                                             <div class="btn-payPal mx-auto p-0" @click="paypal">
                                                <img src="@/assets/cliente/img/shop/paypal.png" alt="PayPal" />
                                             </div>
                                          </td>
                                       </tr>
                                       <tr class="cart_item" v-else-if="dadosCompra.formaPgto == 'MercadoPago'">
                                          <td class="w-100 product-total text-end">
                                             <div class="btn-mercadoPago mx-auto" @click="mercadoPago">
                                                <img src="@/assets/cliente/img/shop/mercadoPago.png" alt="MercadoPago" />
                                             </div>
                                          </td>
                                       </tr>
                                    </tbody>
                                 </table>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </section>
   </main>
</template>

<script>

import router from '@/router'
import Swal from 'sweetalert2'
import { mapState } from 'vuex'
import produto from '@/components/home/Carrinho.vue'

export default {
	name: 'Finalizar',
   data: function() {
		return {
			dados: null,
         dadosCompra: {'formaPgto': null, 'observacoes': '', 'cupom': '', 'descontoTotal': 0},
         valorFrete: 0,
         loaded: false,
         paidFor: false
		}
	},
   computed: {
		... mapState({
         dadosUsuario: state => state.dadosUsuario,
         logado: state => state.logado,
         carrinho: state => state.carrinho,
         urlRestExterna: state => state.urlRestExterna,
         urlRest: state => state.urlRest,
         config: state => state.config
		})
	},
   components: {
		produto
	},
   methods: {
      finalizar : function (transaction) {
         let ref = this;
         let jsonEnvio = {
            'carrinho': ref.carrinho.itens,
            'usuario': ref.dados.user
         }

         this.$store.dispatch('alternarTelaCarregamento', true)

         this.$axios({
            method: 'post',
            url: this.urlRest +'product/checkout',
            headers: { 
               'Content-Type': 'application/json'
            },
            data: jsonEnvio,
            params: {
               transactionId: transaction.id,
               transactionUrl: transaction.url,
               formaPgto: this.dadosCompra.formaPgto,
               acesso: this.dadosUsuario.cliente.acesso,
               observacoes: this.dadosCompra.observacoes,
               codigo: this.dadosCompra.cupom
            }
         }).then(function () {
            ref.$store.dispatch('limparCarrinho')
            router.push('/').catch(function(){})

            Swal.fire({
               icon: 'success',
               title: 'Pedido realizado!'
            });
         }).catch(function (error) {
            if (error.response != undefined) {
               if (error.response.status == 408) {
                  ref.$store.dispatch('deslogar')

                  ref.$toast.fire({
                     icon: 'warning',
                     title: 'Sessão expirada!'
                  });
               } else {
                  ref.$toast.fire({
                     icon: 'error',
                     title: 'Erro: ' + error.response.status
                  });
               }
            } else {
               ref.$toast.fire({
                  icon: 'error',
                  title: error
               });
            }
         }).finally(() => {
            this.$store.dispatch('alternarTelaCarregamento', false)
         });
      },
      buscarUsuario : function () {
         let ref = this;
         this.$store.dispatch('alternarTelaCarregamento', true)

         this.$axios({
            method: 'get',
            url: this.urlRest +'user/getuser',
            params: {
               acesso: this.dadosUsuario.cliente.acesso
            }
         }).then(function (response) {
            ref.dados = response.data

            if (ref.config.tokenMercadoPago != null && ref.config.tokenMercadoPago.trim().length > 0) {
               ref.dadosCompra.formaPgto = 'MercadoPago'
               
            } else if (ref.config.clientIdPayPal != null && ref.config.clientIdPayPal.trim().length > 0 && 
               ref.config.clientSecretPaypal != null && ref.config.clientSecretPaypal.trim().length > 0) {
               ref.dadosCompra.formaPgto = 'PayPal'

            } else if (ref.config.apiKeyPagHiper != null && ref.config.apiKeyPagHiper.trim().length > 0 && 
               ref.config.tokenPagHiper != null && ref.config.tokenPagHiper.trim().length > 0) {
               ref.dadosCompra.formaPgto = 'PagHiper'

            } else {
               ref.dadosCompra.formaPgto = 'Pagamento não configurado'
            }
         }).catch(function (error) {
            if (error.response != undefined) {
               if (error.response.status == 408) {
                  ref.$store.dispatch('deslogar')

                  ref.$toast.fire({
                     icon: 'warning',
                     title: 'Sessão expirada!'
                  });
               } else {
                  ref.$toast.fire({
                     icon: 'error',
                     title: 'Erro: ' + error.response.status
                  });
               }
            } else {
               ref.$toast.fire({
                  icon: 'error',
                  title: error
               });
            }
         }).finally(() => {
            this.$store.dispatch('alternarTelaCarregamento', false)
         });
      },
      pagHiper : function () {
         if (this.dados.user.idJogador == null || String(this.dados.user.idJogador).trim().length == 0) {
            Swal.fire({
               icon: 'error',
               title: 'ID Jogador inválido!'
            });

            return
         }

         let ref = this;
         let orderID = Math.floor(Math.random() * 999999999999999999999);
         this.$store.dispatch('alternarTelaCarregamento', true)

         this.$axios({
            method: 'post',
            url: this.urlRest +'product/paghiper',
            headers: { 
               'Content-Type': 'application/json'
            },
            data: {
               items: [
                  {
                     item_id: 1, 
                     description: 'Compra de itens via ecommerce', 
                     quantity: 1, 
                     price_cents: (ref.carrinho.valorTotal - ref.dadosCompra.descontoTotal).toFixed(2).replace('.', '')
                  }
               ],
               apiKey: ref.config.apiKeyPagHiper,
               order_id: orderID,
               payer_email: ref.dados.user.email,
               payer_name: ref.dados.user.nomeCompleto,
               payer_cpf_cnpj: ref.dados.user.cpf,
               days_due_date: 30
            }
         }).then(function (response) {
            ref.finalizar({'id': response.data.id, 'url': response.data.url})
            window.open(response.data.url)

         }).catch(function (error) {
            if (error.response != undefined) {
               if (error.response.status == 400) {
                  Swal.fire({
                     icon: 'error',
                     title: error.response.data
                  });
               } else {
                  ref.$toast.fire({
                     icon: 'error',
                     title: 'Erro: ' + error.response.status
                  });
               }
            } else {
               ref.$toast.fire({
                  icon: 'error',
                  title: error
               });
            }
         }).finally(() => {
            this.$store.dispatch('alternarTelaCarregamento', false)
         });
      },
      mercadoPago : function () {
         if (this.dados.user.idJogador == null || String(this.dados.user.idJogador).trim().length == 0) {
            Swal.fire({
               icon: 'error',
               title: 'ID Jogador inválido!'
            });

            return
         }

         let ref = this;
         let transactionId = Math.floor(Math.random() * 999999999999999999999)
         this.$store.dispatch('alternarTelaCarregamento', true)

         this.$axios({
            method: 'post',
            url: this.urlRest +'product/mercadopago',
            headers: { 
               'Content-Type': 'application/json'
            },
            data: {
               items: [
                  {
                     title: 'Compra de itens via ecommerce', 
                     quantity: 1, 
                     currency_id: "BRL", 
                     unit_price: parseFloat((ref.carrinho.valorTotal - ref.dadosCompra.descontoTotal).toFixed(2)),
                     picture_url: ref.config.logoBlack
                  }
               ],
               external_reference: transactionId,
               notification_url: ref.urlRestExterna +'product/alterarstatus?transactionId='+ transactionId
            }
         }).then(function (response) {
            ref.finalizar({'id': transactionId, 'url': response.data})
            window.open(response.data)

         }).catch(function (error) {
            if (error.response != undefined) {
               if (error.response.status == 400) {
                  Swal.fire({
                     icon: 'error',
                     title: error.response.data
                  });
               } else {
                  ref.$toast.fire({
                     icon: 'error',
                     title: 'Erro: ' + error.response.status
                  });
               }
            } else {
               ref.$toast.fire({
                  icon: 'error',
                  title: error
               });
            }
         }).finally(() => {
            this.$store.dispatch('alternarTelaCarregamento', false)
         });
      },
      paypal: function () {
         if (this.dados.user.idJogador == null || String(this.dados.user.idJogador).trim().length == 0) {
            Swal.fire({
               icon: 'error',
               title: 'ID Jogador inválido!'
            });

            return
         }

         let ref = this;
         this.$store.dispatch('alternarTelaCarregamento', true)

         this.$axios({
            method: 'post',
            url: this.urlRest +'product/paypal',
            params: {
               valor: (this.carrinho.valorTotal - this.dadosCompra.descontoTotal).toFixed(2),
               returnUrl: window.location.href + 'retornoPayPal'
            }
         }).then(function (response) {
            ref.finalizar({'id': response.data.id, 'url': response.data.url})
            window.open(response.data.url)

         }).catch(function (error) {
            if (error.response != undefined) {
               if (error.response.status == 400) {
                  Swal.fire({
                     icon: 'error',
                     title: error.response.data
                  });
               } else {
                  ref.$toast.fire({
                     icon: 'error',
                     title: 'Erro: ' + error.response.status
                  });
               }
            } else {
               ref.$toast.fire({
                  icon: 'error',
                  title: error
               });
            }
         }).finally(() => {
            this.$store.dispatch('alternarTelaCarregamento', false)
         });
      },
      aplicarCupom: function() {
         let ref = this;

         Swal.fire({
            icon: 'warning',
            title: 'Aplicar cupom?',
            text: 'Cupom: "'+ ref.dadosCompra.cupom +'"',
            showCancelButton: true,
            cancelButtonText: 'Cancelar',
            confirmButtonText: 'Confirmar'

         }).then((result) => {
            if (result.isConfirmed) {
               if (ref.dadosCompra.cupom == null || ref.dadosCompra.cupom.trim().length < 3) {
                  ref.$toast.fire({
                     icon: 'error',
                     title: 'Cupom inválido!'
                  });
      
                  return
               }
               
               ref.$store.dispatch('alternarTelaCarregamento', true)
      
               ref.$axios({
                  method: 'get',
                  url: ref.urlRest +'product/validarcupom',
                  params: {
                     codigo: ref.dadosCompra.cupom
                  }
               }).then(function (response) {
                  ref.$refs.cupom.disabled = 'true'
                  ref.dadosCompra.descontoTotal = parseFloat(ref.carrinho.valorTotal) * (parseFloat(response.data) / 100);

                  ref.$toast.fire({
                     icon: 'success',
                     title: 'Cupom aplicado!'
                  });
               }).catch(function (error) {
                  ref.dadosCompra.cupom = '';

                  if (error.response != undefined) {
                     if (error.response.status == 400) {
                        ref.$toast.fire({
                           icon: 'error',
                           title: 'Cupom inválido!'
                        });
                     } else {
                        ref.$toast.fire({
                           icon: 'error',
                           title: 'Erro: ' + error.response.status
                        });
                     }
                  } else {
                     ref.$toast.fire({
                        icon: 'error',
                        title: error
                     });
                  }
               }).finally(() => {
                  ref.$store.dispatch('alternarTelaCarregamento', false)
               });
            }
         })
      }
   },
   created() {
      this.$store.dispatch('toggleCarrinho', false)

      if (this.logado.cliente) {
         this.buscarUsuario()

      } else {
         Swal.fire({
            icon: 'warning',
            title: 'Entre para continuar!',
            showCancelButton: false,
            confirmButtonText: 'Entrar'

         }).then(function () {
            router.push('/login').catch(function(){})
         })
      }
   }
}

</script>